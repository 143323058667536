<template>
  <div class="marks">
    <section class="section fullheight">
      <div class="content">
        <div class="text">
          <h2>Contact Info</h2>
          <p>contact@moodmarks.org<br />
          +46 (0) 73 025 91 82</p>
        </div>

        <img class="icon" src="@/assets/icon.svg" alt="">

        <p class="long-text">
          Mood Marks is a typography project created by Isak Hultström Swahn who
          holds a BFA in Visual Communication from Beckmans School of Design.-)
        </p>
      </div>
    </section>

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Contact',

  components: {
    AppFooter,
  },
};
</script>

<style lang="scss" scoped>
.section {
  @include section;
}

.content {
  width: 100%;
  max-width: 1132px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 100px 40px;

  @include large {
    padding: 0 40px;
  }
}

.text {
  font-size: 14px;

  @include large {
    h2, p {
      font-size: 24px;
    }
  }
}

.icon {
  width: 387px;
  height: 387px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

.long-text {
  text-align: center;
  font-size: 14px;

  @include large {
    display: none;
  }
}
</style>
